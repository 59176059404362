<template>
  <div class="col-12 col-lg-10 c g">
    <div class="row">
        <div class="col-12 col-lg-4 g" v-for="device in devices" :key="device._id">
            <div class="card" style="border-radius: 20pxs">
                <div class="card-body ddd" @click="gotochat(device._id)" 
                            v-if="device.status == 1" style="border-radius: 20px">
                    <div class="col-12 text-center g" v-if="device.info.image">
                        <img :src="device.info.image" style="width: 50%; border-radius: 20px" alt="">
                    </div>
                    <h4 class="text-center g">
                        {{ device.title }}
                    </h4>
                    <div class="col-12 text-center g">
                        <span
                        class="badge bg-warning"
                        v-if="device.status == 0"
                        >
                        <i class="fa fa-clock-o"></i>
                        لم يتصل بعد
                        </span>
                        <span
                        class="badge bg-success"
                        v-if="device.status == 1"
                        >
                        <i class="fa fa-check"></i>
                        متصل الآن
                        </span>
                        <span class="badge bg-danger" v-if="device.status == 2">
                        <i class="fa fa-times"></i>
                        قُطع الاتصال
                        </span>
                        <span class="badge bg-danger" v-if="device.status == 3">
                        <i class="fa fa-times"></i>
                        الرقم جديد!ّ ربما يتم حظره اذا تم الارسال منه
                        </span></div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BModal, VBModal } from "bootstrap-vue";
var QRCode = require("qrcode");
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  beforeDestroy() {
    if (this.inertValDevices) {
      clearInterval(this.inertValDevices);
    }
  },
  data(){
    return {
      user: JSON.parse(localStorage.getItem('user')),
      loading: false,
      devices: [],
      inertValDevices: null,
      current_device: {},
      qrcodeaftergenerate: ""
    }
  },
  created(){
    var g = this;
    this.loading = true
    g.getDevices()
    g.inertValDevices = setInterval(() => {
      g.getDevices(true);
    }, 5000);
  },
  methods: {
    gotochat(id){
      window.location = '/chat#' + id
    },
    getDevices(){
    var g = this;
    $.post(api + '/user/devices/list', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.devices = r.response
            }
        }).fail(function(){
            g.loading = false
        })
    },
  }
}
</script>

<style>
.ddd:hover{
    background: #eee;
    cursor: pointer;
}
</style>